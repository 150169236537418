import Button from '@mui/material/Button';
import React, {useState} from 'react';
import './App.scss';
import {StoryInput} from './story-input/story-input';
import {CircularProgress} from '@mui/material';
import {IStoryInput} from '../interfaces/story-input.interface';

export default () => {
  const [storyInput, setStoryInput] = useState<IStoryInput>({gender: '', name: ''});
  const [storyText, setStoryText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  async function go(): Promise<void> {
    setLoading(true);
    setStoryText('');
    setError(null);

    try {
      const res: Response = await fetch(`https://213-52-130-161.ip.linodeusercontent.com/${storyInput.name}/${storyInput.gender}`);
      const text = (await res.json()).data;
      setStoryText(text);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  }

  return (
    <>
      <div className='container'>
        <h2>Tell me a story about a...</h2>
        <StoryInput onChange={(i: IStoryInput) => setStoryInput(i)} loading={loading}/>
        <Button variant="contained" color='success' disabled={loading || !storyInput.gender || !storyInput.name} onClick={go}>Go</Button>
      </div>

      {storyText &&
      <div className="container story">
        <p>{storyText}</p>
        <p>The end.</p>
      </div>}

      {loading &&
      <div className="container">
        <p>Writing your story...</p>
        <CircularProgress/>
      </div>}

      {error &&
      <div className="container">
        <p>Sorry - something went wrong</p>
      </div>}
    </>
  );
}
